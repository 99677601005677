import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { thunk } from 'redux-thunk';
import rootReducer from './reducers/root';
import { configureStore } from '@reduxjs/toolkit';

const tabId = window.name || Math.random().toString(36).substring(2, 15);
window.name = tabId; 

const persistConfig = {
    key: 'root',
    storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE', 'persist/PURGE'],
        }
    }).concat(thunk),
});

export const persistor = persistStore(store);
