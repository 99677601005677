import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import {HeaderSpacer} from "../Header/HeaderSpacer";

const isTouchDevice = () => {
    return (
        'ontouchstart' in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
    );
};

const backend = isTouchDevice() ? TouchBackend : HTML5Backend;

const DndComponent = ({ projectState }) => {
    const onDragStart = (event, nodeType) => {
        event.dataTransfer.setData('application/reactflow', nodeType);
        event.dataTransfer.effectAllowed = 'move';
    };

    return (
        <DndProvider backend={backend}>
            <aside style={{
                background: "rgba(173,173,173,0.3)",
                backdropFilter: "blur(2px)",
                WebkitBackdropFilter: "blur(2px)",
                width: "250px",
                position: "fixed",
                height: "150vh",
                overflow: "auto",
                padding: "1rem",
                right: "0",
                zIndex: "99",

            }}>
                <HeaderSpacer/>
                {(["IDLE", "ERROR"].includes(projectState)) && (  // SPECIAL MODEL ADDING
                    <>
                        <div className="description">You can drag these nodes to the pane on the right.</div>
                        <div className="dndnode" onDragStart={(event) => onDragStart(event, 'AI')} draggable>
                            AI Node
                        </div>
                        <div className="dndnode" onDragStart={(event) => onDragStart(event, 'CUSTOM_PYTHON')} draggable>
                            Custom Python Node
                        </div>
                        <div className="dndnode" onDragStart={(event) => onDragStart(event, 'LLM')} draggable>
                            LLM Node
                        </div>
                        <div className="dndnode" onDragStart={(event) => onDragStart(event, 'SDXL-1-0')} draggable>
                            SDXL 1.0
                        </div>
                        <div className="dndnode"
                             onDragStart={(event) => onDragStart(event, 'SDXL-Turbo-Dreamshaper-v2')} draggable>
                            SD DreamShaper v2.1 Turbo
                        </div>
                        <div className="dndnode" onDragStart={(event) => onDragStart(event, 'SDXL-Turbo-Accelerated')}
                             draggable>
                            ⚡️SDXL Turbo
                        </div>
                        <div className="dndnode"
                             onDragStart={(event) => onDragStart(event, 'SDXL-Turbo-FMAAccelerated')} draggable>
                            ⚡️SDXL Turbo Accelerated
                        </div>
                        <div className="dndnode"
                             onDragStart={(event) => onDragStart(event, 'SDXL-1-0-Lightning-Accelerated')} draggable>
                            ⚡️SDXL Lightning
                        </div>
                        <div className="dndnode" onDragStart={(event) => onDragStart(event, 'SD-Cascade')} draggable>
                            SD Cascade
                        </div>
                        <div className="dndnode" onDragStart={(event) => onDragStart(event, 'Whisper')} draggable>
                            Whisper
                        </div>
                        {/*<div className="dndnode" onDragStart={(event) => onDragStart(event, 'XTTS-v2')} draggable>*/}
                        {/*    XTTS-v2*/}
                        {/*</div>*/}
                        <div className="dndnode" onDragStart={(event) => onDragStart(event, 'Flux-Dev')} draggable>
                            Flux Dev
                        </div>
                        <div className="dndnode" onDragStart={(event) => onDragStart(event, 'Flux-Schnell')} draggable>
                            Flux Schnell
                        </div>
                    </>
                )}
            </aside>
        </DndProvider>
    );
};

export default DndComponent;
