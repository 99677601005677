import React from "react";
import { Image } from "react-bootstrap";
import styles from "../styles/Playground.module.css";

import download from "../images/download.svg";
import vector from "../../../Header/NavBar/images/Vector 912.png";

export default function DownloadShare({value, type}) {
    function downloadAndConvert(value: Array<string>, type: string) {
        const link = document.createElement("a");
        let dataType = "";
        let nameFile = "";
        switch (type) {
            case "image/*":
                dataType = "data:image/jpeg;base64";
                nameFile = ".jpeg";
                break;
            case "image/png":
                dataType = "data:image/png;base64";
                nameFile = ".png";
                break;
            case "audio/*":
                dataType = "data:audio/wave;base64";
                nameFile = ".wav";
                break;
            case "video/*":
                dataType = "data:image/*;base64";
                nameFile = ".jpeg";
                break;
        }
        value.map((item: string, index: number) => {
            link.href = `${dataType},${item}`;
            link.download = `result_${index}${nameFile}`;
            link.click();
        })

    }
    return (
        <>
            <div className={styles.containerBtnResult}>
                <button
                    onClick={_ => downloadAndConvert(value, type)}
                    className={styles.buttonResult}
                >
                    Download <Image
                        style={{ paddingBottom: "2px" }}
                        height={14}
                        src={download}
                        alt="download"
                    />
                </button>
            </div>
            <Image src={vector} alt="divider" width={"100%"} height={2} className="pe-0 ps-2" />
        </>
    );
}